
























import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class GenericToggleButton extends Vue {
  @Prop({type: Boolean, default: false}) default!: boolean | undefined

  value = this.default ?? false

  changeValue(event: Event) {
    event.stopPropagation()

    this.value = !this.value
    this.$emit('handleToggle', this.value)
  }
}
