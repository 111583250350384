


















































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {InputGlobalSchema} from '@/schema/resource/Global/InputGlobalSchema'
import GenericToggleButton from '@/components/misc/GenericToggleButton.vue'
import {Global} from '@/model/resource/Global'

@Component({
  components: {GenericToggleButton},
})
export default class PersistGlobalView extends Vue {
  @Provide('validator') validator = this.$validator

  schema = new InputGlobalSchema()
  global = this.$auth.global

  async created() {
    await this.populate()
  }

  async populate() {
    this.$await.done('getGlobal')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.global.persistGlobal()
    this.$toast.success('system.success.persist')
  }

  async handleToggleSwapFeature(value: boolean) {
    await Global.toggleSwapFeature(value)
    this.$toast.success('system.success.persist')
  }
}
